<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'counselingClassesDetail',
              params: { id: $route.params.id }
            }"
          >
            <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
            {{ sessionClass.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div v-id="teacher" style="margin-bottom: 48px">
      <el-row>
        <el-col :span="12">
          <h1>
            {{ pageTitle }}
            <router-link
              v-if="isRoleAdmin() || isRoleTeacher()"
              :to="{
                name: 'privateClassesCreateLog',
                params: { privateType: 'counseling', id: $route.params.id }
              }"
            >
              <i class="el-icon-plus action-icon" />
            </router-link>
          </h1>
        </el-col>
        <el-col :span="10">
          <el-button
            type="primary"
            v-if="isRoleAdmin() || !isRoleStudent()"
            @click="
              () => {
                filterPaid = !filterPaid;
              }
            "
          >
            Show {{ filterPaid ? "All" : "Unpaid" }}
          </el-button>
          <el-button
            type="primary"
            v-if="
              (isRoleAdmin() || isRoleTeacher()) &&
                existOldStudentsOrTeachers &&
                !i_am_old_teacher
            "
            @click="
              () => {
                filterActive = !filterActive;
              }
            "
          >
            Show
            {{
              filterActive
                ? "Active Teacher/Students Only"
                : "With Inactive Teachers/Students"
            }}
          </el-button>
        </el-col>
      </el-row>
      <hr class="separate-line" />
      <el-tabs v-model="currenctLessonTab">
        <template
          v-if="(isRoleAdmin() || isRoleTeacher()) && !this.i_am_old_teacher"
        >
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="to"
            start-placeholder="Start date"
            end-placeholder="End date"
            value-format="yyyy-MM-dd"
            @change="handleDateChange"
            @clear="handleDateChange"
            style="margin-bottom: 20px; width:400px;"
          />
          <el-tab-pane
            :name="String(teacher.teacher_user_id)"
            :key="teacher.teacher_user_id"
            v-for="teacher in teachers"
          >
            <template slot="label">
              <i class="fas fa-user-graduate" />
              {{ teacher.teacher_user.name }}
            </template>
            <TeachingLogs
              privateType="counseling"
              :classId="Number($route.params.id)"
              :teachingLogs="teachingLogs[teacher.teacher_user_id]"
              :teacherUserId="teacher.teacher_user_id"
              :teacherUserName="teacher.teacher_user.name"
              :filterPaid="filterPaid"
              @reloadCustomLesson="initialCustomLesson"
            />
          </el-tab-pane>
        </template>
        <template
          v-if="
            (filterActive == false && (isRoleAdmin() || isRoleTeacher())) ||
              this.i_am_old_teacher
          "
        >
          <el-tab-pane
            :key="old_teacher.id"
            v-for="old_teacher in old_teachers"
            :name="String(old_teacher.id)"
          >
            <template slot="label">
              <i class="fas fa-user-graduate old-tab" />
              <span class="old-tab">{{ old_teacher.name }}</span>
            </template>
            <el-alert
              title="老師目前已沒有教這個班級"
              type="warning"
              :closable="false"
              show-icon
            >
            </el-alert>
            <TeachingLogs
              privateType="counseling"
              :classId="Number($route.params.id)"
              :teachingLogs="teachingLogs[old_teacher.id]"
              :teacherUserId="old_teacher.id"
              :teacherUserName="old_teacher.name"
              :filterPaid="filterPaid"
              :showSalary="old_teacher.id == my_id"
              @reloadCustomLesson="initialCustomLesson"
            />
          </el-tab-pane>
        </template>
        <template>
          <el-tab-pane
            :key="student.id"
            v-for="{ student } in students"
            :label="student.name"
            :name="String(student.id)"
          >
            <template slot="label">
              <i class="far fa-user" />
              {{ student.name }}
            </template>
            <LessonLogs
              privateType="counseling"
              :classId="Number($route.params.id)"
              :lessonLogs="lessonLogs[student.id]"
              :enrollSessionId="getEnrollSessionId(student.id)"
              :filterPaid="filterPaid"
              @reloadCustomLesson="initialCustomLesson"
            />
          </el-tab-pane>
        </template>
        <template v-if="filterActive == false">
          <el-tab-pane
            :key="student.id"
            v-for="{ student } in old_students"
            :label="student.name"
            :name="String(student.id)"
          >
            <template slot="label">
              <i class="far fa-user old-tab" />
              <span class="old-tab">{{ student.name }}</span>
            </template>
            <el-alert
              title="學生目前已不在此班上"
              type="warning"
              :closable="false"
              show-icon
            >
            </el-alert>
            <LessonLogs
              privateType="counseling"
              :classId="Number($route.params.id)"
              :lessonLogs="lessonLogs[student.id]"
              :enrollSessionId="getOldEnrollSessionId(student.id)"
              :filterPaid="filterPaid"
              @reloadCustomLesson="initialCustomLesson"
            />
          </el-tab-pane>
        </template>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import TeachingLogs from "@/views/lessonLog/TeachingLogs";
import LessonLogs from "@/views/lessonLog/LessonLogs";
import privateApi from "@/apis/private";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: "Meeting Logs - Ivy-Way Academy"
    };
  },
  mixins: [moneyMixin, roleMixin],
  components: {
    Breadcrumb,
    IsOnlineIcon,
    TeachingLogs,
    LessonLogs
  },
  computed: {
    pageTitle() {
      return `${this.students[0].student.first_name}'s Meeting Logs - ${
        this.filterPaid ? "Unpaid" : "All"
      }`;
    },
    existOldStudentsOrTeachers() {
      return this.old_students.length > 0 || this.old_teachers.length > 0;
    }
  },
  data() {
    return {
      dateRange: [],
      filterPaid: false,
      filterActive: true,
      scheduledVisible: false,
      subject: "",
      students: [],
      old_students: [],
      teachers: [],
      old_teachers: [],
      lessonLogs: {},
      teachingLogs: {},
      sessionClass: {
        course_session: {}
      },
      teacher: null,
      currenctLessonTab: null,
      i_am_old_teacher: 0,
      my_id: 0
    };
  },
  async created() {
    await this.initialCustomLesson();
  },
  methods: {
    getEnrollSessionId(studentId) {
      return this.students.find(
        student => student.student_user_id === studentId
      ).enrolled_session_id;
    },
    handleDateChange() {
      this.initialCustomLesson();
    },
    async initialCustomLesson() {
      const filterCanNotSeeOtherTeacher = teachers => {
        if (this.isRoleAdmin()) return teachers;
        const meetingTeacher = teachers.find(
          ({ teacher_user_id }) =>
            teacher_user_id === this.$store.getters["user/getProfile"].id
        );
        if (meetingTeacher.can_see_others) return teachers;
        return [meetingTeacher];
      };

      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.id,
        from:
          this.dateRange && this.dateRange.length > 0
            ? this.dateRange[0]
            : null,
        to:
          this.dateRange && this.dateRange.length > 1 ? this.dateRange[1] : null
      });
      this.sessionClass = {
        ...customLesson.session_class
      };

      this.students = customLesson.student_class_tickets;
      this.old_students = customLesson.old_student_class_tickets;
      this.old_teachers = customLesson.old_teachers;

      if (this.isRoleAdmin() || this.isRoleTeacher()) {
        this.teachers = filterCanNotSeeOtherTeacher(
          this.sessionClass.session_class_teachers
        );
        this.currenctLessonTab = String(this.teachers[0].teacher_user_id);
      } else {
        this.currenctLessonTab = String(this.students[0].student.id);
      }

      this.teacher = customLesson.teacher_user;
      if (this.teacher) {
        this.teachingLogs = customLesson.teaching_logs_group_by_user;
        this.lessonLogs = customLesson.lesson_logs_group_by_user;
      }
      this.i_am_old_teacher = customLesson.i_am_old_teacher;
      this.my_id = customLesson.my_id;
      if (this.i_am_old_teacher == 1) {
        this.currenctLessonTab = this.my_id;
      }
    }
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'counselingClassesDetail',
            params: { id: _vm.$route.params.id }
          }}},[_c('IsOnlineIcon',{attrs:{"isOnline":_vm.sessionClass.course_session.is_online}}),_vm._v(" "+_vm._s(_vm.sessionClass.title)+" ")],1)],1),_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],1)],2),_c('div',{directives:[{name:"id",rawName:"v-id",value:(_vm.teacher),expression:"teacher"}],staticStyle:{"margin-bottom":"48px"}},[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('h1',[_vm._v(" "+_vm._s(_vm.pageTitle)+" "),(_vm.isRoleAdmin() || _vm.isRoleTeacher())?_c('router-link',{attrs:{"to":{
              name: 'privateClassesCreateLog',
              params: { privateType: 'counseling', id: _vm.$route.params.id }
            }}},[_c('i',{staticClass:"el-icon-plus action-icon"})]):_vm._e()],1)]),_c('el-col',{attrs:{"span":10}},[(_vm.isRoleAdmin() || !_vm.isRoleStudent())?_c('el-button',{attrs:{"type":"primary"},on:{"click":() => {
              _vm.filterPaid = !_vm.filterPaid;
            }}},[_vm._v(" Show "+_vm._s(_vm.filterPaid ? "All" : "Unpaid")+" ")]):_vm._e(),(
            (_vm.isRoleAdmin() || _vm.isRoleTeacher()) &&
              _vm.existOldStudentsOrTeachers &&
              !_vm.i_am_old_teacher
          )?_c('el-button',{attrs:{"type":"primary"},on:{"click":() => {
              _vm.filterActive = !_vm.filterActive;
            }}},[_vm._v(" Show "+_vm._s(_vm.filterActive ? "Active Teacher/Students Only" : "With Inactive Teachers/Students")+" ")]):_vm._e()],1)],1),_c('hr',{staticClass:"separate-line"}),_c('el-tabs',{model:{value:(_vm.currenctLessonTab),callback:function ($$v) {_vm.currenctLessonTab=$$v},expression:"currenctLessonTab"}},[((_vm.isRoleAdmin() || _vm.isRoleTeacher()) && !this.i_am_old_teacher)?[_c('el-date-picker',{staticStyle:{"margin-bottom":"20px","width":"400px"},attrs:{"type":"daterange","range-separator":"to","start-placeholder":"Start date","end-placeholder":"End date","value-format":"yyyy-MM-dd"},on:{"change":_vm.handleDateChange,"clear":_vm.handleDateChange},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_vm._l((_vm.teachers),function(teacher){return _c('el-tab-pane',{key:teacher.teacher_user_id,attrs:{"name":String(teacher.teacher_user_id)}},[_c('template',{slot:"label"},[_c('i',{staticClass:"fas fa-user-graduate"}),_vm._v(" "+_vm._s(teacher.teacher_user.name)+" ")]),_c('TeachingLogs',{attrs:{"privateType":"counseling","classId":Number(_vm.$route.params.id),"teachingLogs":_vm.teachingLogs[teacher.teacher_user_id],"teacherUserId":teacher.teacher_user_id,"teacherUserName":teacher.teacher_user.name,"filterPaid":_vm.filterPaid},on:{"reloadCustomLesson":_vm.initialCustomLesson}})],2)})]:_vm._e(),(
          (_vm.filterActive == false && (_vm.isRoleAdmin() || _vm.isRoleTeacher())) ||
            this.i_am_old_teacher
        )?_vm._l((_vm.old_teachers),function(old_teacher){return _c('el-tab-pane',{key:old_teacher.id,attrs:{"name":String(old_teacher.id)}},[_c('template',{slot:"label"},[_c('i',{staticClass:"fas fa-user-graduate old-tab"}),_c('span',{staticClass:"old-tab"},[_vm._v(_vm._s(old_teacher.name))])]),_c('el-alert',{attrs:{"title":"老師目前已沒有教這個班級","type":"warning","closable":false,"show-icon":""}}),_c('TeachingLogs',{attrs:{"privateType":"counseling","classId":Number(_vm.$route.params.id),"teachingLogs":_vm.teachingLogs[old_teacher.id],"teacherUserId":old_teacher.id,"teacherUserName":old_teacher.name,"filterPaid":_vm.filterPaid,"showSalary":old_teacher.id == _vm.my_id},on:{"reloadCustomLesson":_vm.initialCustomLesson}})],2)}):_vm._e(),_vm._l((_vm.students),function({ student }){return _c('el-tab-pane',{key:student.id,attrs:{"label":student.name,"name":String(student.id)}},[_c('template',{slot:"label"},[_c('i',{staticClass:"far fa-user"}),_vm._v(" "+_vm._s(student.name)+" ")]),_c('LessonLogs',{attrs:{"privateType":"counseling","classId":Number(_vm.$route.params.id),"lessonLogs":_vm.lessonLogs[student.id],"enrollSessionId":_vm.getEnrollSessionId(student.id),"filterPaid":_vm.filterPaid},on:{"reloadCustomLesson":_vm.initialCustomLesson}})],2)}),(_vm.filterActive == false)?_vm._l((_vm.old_students),function({ student }){return _c('el-tab-pane',{key:student.id,attrs:{"label":student.name,"name":String(student.id)}},[_c('template',{slot:"label"},[_c('i',{staticClass:"far fa-user old-tab"}),_c('span',{staticClass:"old-tab"},[_vm._v(_vm._s(student.name))])]),_c('el-alert',{attrs:{"title":"學生目前已不在此班上","type":"warning","closable":false,"show-icon":""}}),_c('LessonLogs',{attrs:{"privateType":"counseling","classId":Number(_vm.$route.params.id),"lessonLogs":_vm.lessonLogs[student.id],"enrollSessionId":_vm.getOldEnrollSessionId(student.id),"filterPaid":_vm.filterPaid},on:{"reloadCustomLesson":_vm.initialCustomLesson}})],2)}):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }